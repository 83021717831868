import { dev } from '$app/environment';
import { PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public';
import { handleErrorWithSentry, init, feedbackIntegration } from '@sentry/sveltekit';

if (!dev) {
	/*init({
		dsn: 'https://fc345da1c3ed4b6482151ae1d2beb365@glitchtip.tingo.ai//1',
		tracesSampleRate: 1.0,
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		environment: PUBLIC_SENTRY_ENVIRONMENT,
	});*/
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
