import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [2,5,4];

export const dictionary = {
		"/(app)": [~6,[2]],
		"/(app)/about": [7,[2]],
		"/(app)/activity": [8,[2]],
		"/admin": [46,[5]],
		"/admin/analytics": [47,[5]],
		"/admin/banners": [48,[5]],
		"/admin/characters-all": [50,[5]],
		"/admin/characters": [49,[5]],
		"/admin/chat/export": [51,[5]],
		"/admin/create-multiple": [~53,[5]],
		"/admin/create": [~52,[5]],
		"/admin/generate": [~54,[5]],
		"/admin/users": [55,[5]],
		"/(app)/affiliate": [9,[2]],
		"/(seo)/ai-girlfriend-chatbot": [44,[4]],
		"/(seo)/blog/[slug]": [~45,[4]],
		"/(app)/categories": [~10,[2]],
		"/(app)/chats": [~11,[2]],
		"/(app)/clone": [~12,[2]],
		"/(app)/coin-purchase": [~13,[2]],
		"/(app)/community": [14,[2]],
		"/(app)/create": [~15,[2]],
		"/(app)/documents": [16,[2,3]],
		"/(app)/documents/18-USC-2257-Exemption": [17,[2,3]],
		"/(app)/documents/cookies-policy": [18,[2,3]],
		"/(app)/documents/privacy-policy": [19,[2,3]],
		"/(app)/documents/refund-policy": [20,[2,3]],
		"/(app)/documents/terms-conditions": [21,[2,3]],
		"/(app)/email-verification": [22,[2]],
		"/(app)/faq": [23,[2]],
		"/(app)/freeTrial": [24,[2]],
		"/(app)/generate": [~25,[2]],
		"/(app)/login": [~26,[2]],
		"/(app)/membersOnly": [27,[2]],
		"/(app)/my-gallery": [~29,[2]],
		"/(app)/my-gallery/[id]": [30,[2]],
		"/(app)/my": [~28,[2]],
		"/(app)/profiles/[id]": [~32,[2]],
		"/(app)/profile": [~31,[2]],
		"/(marketing)/promo001": [42],
		"/(marketing)/promo002": [43],
		"/(app)/ranking": [~33,[2]],
		"/(app)/reset-password": [~34,[2]],
		"/(app)/reset-password/[token]": [~35,[2]],
		"/(app)/rewards": [~36,[2]],
		"/(app)/shop": [~37,[2]],
		"/(app)/shop/success": [38,[2]],
		"/(app)/signup": [~39,[2]],
		"/(app)/subscribe": [40,[2]],
		"/(app)/support": [~41,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';